import styled from "@emotion/styled"
import React, { useState } from "react"
import { colors, fonts, device } from "./layout/GlobalStyles"
import { StaticImage } from "gatsby-plugin-image"
import { Tooltip } from "./Tooltip"

const StyledWeRiseTogether = styled.div`
  background: ${colors.white};

  .outter-contain {
    z-index: 1;
    position: relative;
    padding: 72px 0px 0px;
  }
  .bottom-image {
    z-index: 1;
    bottom: -0.1em;
    left: -4em;
    min-width: 130vw;
    img {
      min-width: 100vw;
    }
  }
  .special {
    font-weight: 700;
    cursor: pointer;
    &:hover {
      font-weight: 700;
    }
  }
  span {
    cursor: pointer;
    font-weight: 300;
  }

  @media ${device.tablet} {
    padding: 72px 0px 28px;
    .bottom-image {
      z-index: 0;
      bottom: -2.4em;
      left: 0em;
      width: 100vw;
      img {
        width: 100vw;
      }
    }
  }
  @media ${device.tablet} {
    .outter-contain {
      padding: 272px 0px 0px;
    }
  }
  @media ${device.laptop} {
    .outter-contain {
      z-index: 1;
      position: relative;
      padding: 72px 0px 0px;
    }
    .bottom-image {
      z-index: 0;
      left: 0em;
      width: 100vw;
      img {
        width: 100vw;
      }
    }
  }
  @media ${device.laptopL} {
  }
  @media ${device.desktopL} {
  }
  @media only screen and (min-width: 2100px) {
  }
  @media only screen and (min-width: 2500px) {
  }
`

const Title = styled.div`
  z-index: 2;
  h4 {
    margin: 0em;
    font-weight: 800;
    letter-spacing: -1.88px;
    line-height: 65px;
    color: ${colors.primary2};
    font-family: ${fonts.secondary};
    font-size: 60px;
  }

  @media ${device.tablet} and (orientation: portrait) {
    span {
      font-size: 90px;
    }
  }
  @media ${device.tablet} and (orientation: landscape) {
    span {
      font-size: 140px;
    }
  }
  @media ${device.laptop} {
    h4 {
      line-height: 85px;
    }
  }
`

const Panels = styled.div`
  margin-bottom: -30px;

  @media ${device.tablet} {
    margin-bottom: -30px;
  }
  @media ${device.laptop} {
    margin-bottom: -320px;
    display: flex;
    justify-content: space-between;
  }
`

const Panel = styled.section`
  z-index: 2;

  p {
  }
  .first-par {
    color: ${colors.dark3};
    font-size: 30px;
    font-weight: 900;
    margin: 30px 0px 0px;
    letter-spacing: -0.94px;

    line-height: 36px;
  }

  .last-par {
    font-size: 22px;
    margin: 10px 0px 0px;
    font-weight: 400;
    letter-spacing: -0.69px;
    line-height: 36px;
  }
  @media ${device.tablet} and (orientation: portrait) {
    h4 {
      text-align: center;
    }
  }
  @media ${device.laptop} {
    width: 243px;
  }
`
const tooltipText = [
  "We define these as Individuals or families living on less than US $1.90 per person per day in 2011 purchasing power parity (PPP) in the world's poorest countries. In lower-and upper-middle-income countries, $3.20 and $5.50 per person per day, respectively.",
]

export const WeRiseTogether = ({
  setToolTipMobile,
  toolTipMobile,
  hovered,
  setHovered,
}) => {
  const [name, setName] = useState("")
  const hoverAction = (bool, name) => {
    setName(name)
    setHovered(bool)
  }
  return (
    <StyledWeRiseTogether>
      <div className="container">
        <div className="outter-contain">
          <Title>
            <h4>
              We <br />
              <span>R.I.S.E.</span> Together
            </h4>
          </Title>
        </div>
        <Panels>
          <Panel>
            <p className="first-par">Representation</p>
            <p className="last-par">
              We center and amplify the voices of those at the{" "}
              <span
                style={{ fontWeight: "700" }}
                onClick={() => setToolTipMobile(active => !active)}
                onMouseOver={() => hoverAction(true, "margins")}
                onMouseLeave={() => hoverAction(false, "")}
              >
                {name === "margins" ? (
                  <Tooltip
                    buttonText="View full glossary"
                    toolTipMobile={toolTipMobile}
                    setToolTipMobile={setToolTipMobile}
                    text={tooltipText[0]}
                    hovered={hovered}
                    setHovered={setHovered}
                  >
                    margins
                  </Tooltip>
                ) : (
                  <div className="special">margins</div>
                )}
              </span>{" "}
              to be seen, known, felt, <br />
              and heard.
            </p>
          </Panel>
          <Panel>
            <p className="first-par">Inclusivity</p>
            <p className="last-par special-paragraph">
              We unconditionally welcome and affirm diverse voices and
              experiences in a supportive environment saturated with grace.
            </p>
          </Panel>
          <Panel>
            <p className="first-par">Service</p>
            <p className="last-par">
              We focus on people, not numbers. Measuring our impact by the
              success of those we amplify rather than the numbers we serve.
            </p>
          </Panel>
          <Panel>
            <p className="first-par">Engagement</p>
            <p className="last-par">
              We evaluate our programs through the lens of equitable systemic
              change, sustainability, and the examination of power dynamics.
            </p>
          </Panel>
        </Panels>
      </div>
      <StaticImage
        className="bottom-image"
        src="../images/people-jumping.jpeg"
        placeholder="none"
        quality={100}
        alt="people jumping"
      />
    </StyledWeRiseTogether>
  )
}
