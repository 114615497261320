import styled from "@emotion/styled"
import React from "react"
import { colors, device } from "./layout/GlobalStyles"

const StyledGuidingPhilosophy = styled.div`
  background: ${colors.primary2};
  padding: 129px 0em;
  margin: -1px 0em;
  h2,
  p {
    color: ${colors.white};
    margin: 0;
  }
  h2 {
    margin-bottom: 0.5em;
    font-size: 50px;
    font-weight: 800;
    letter-spacing: -1.56px;
    line-height: 55px;
  }
  p {
    font-size: 22px;
    font-weight: 400;
    letter-spacing: -0.69px;
    line-height: 36px;
  }
  @media ${device.laptop} {
    .container {
      display: flex;
      padding: 0em;
      justify-content: space-between;
    }
    h1 {
      padding: 0em;
    }
    p {
      max-width: 648px;

      margin: 0px 92.6px 0px 0px;
    }
  }
`

export const GuidingPhilosophy = () => {
  return (
    <StyledGuidingPhilosophy>
      <div className="container">
        <h2>Guiding Philosophy</h2>
        <p>
          MLIFE favors ground-truthed expertise and grassroots impact over
          paternalistic philanthropic models rooted in global white supremacy.
          We find those on the ground doing good work in the most challenging
          conditions and trust them.
        </p>
      </div>
    </StyledGuidingPhilosophy>
  )
}
