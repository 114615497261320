import styled from "@emotion/styled"
import React from "react"
import { colors, fonts, device } from "./layout/GlobalStyles"
import poverty from "../images/lessen-poverty.svg"
import leaders from "../images/build-leaders.svg"
import peace from "../images/promote-peace.svg"

const StyledWhatWeDo = styled.div`
  background: ${colors.white};
  padding: 55px 0em 10px;
  .column-container {
    max-width: 350px;
    margin: 0 auto;
  }
  @media ${device.tablet} and (orientation: portrait) {
    padding: 80px 0em;
    .column-container {
      max-width: 750px;
      margin: 0 auto;
    }
  }
  @media ${device.tablet} and (orientation: landscape) {
    padding: 128px 0em;
    .column-container {
      max-width: 650px;
      margin: 0 auto;
    }
  }
  @media ${device.tablet} and (orientation: landscape) {
    padding: 128px 0em;
    .column-container {
      max-width: 1140px;
      margin: 0 auto;
    }
  }
`

const Title = styled.section`
  h2 {
    text-align: center;
    font-weight: 800;
    letter-spacing: -1.88px;
    line-height: 45px;
    margin: 0em;
    font-size: 50px;
  }

  p {
    font-size: 22px;
    text-align: center;
  }
  @media ${device.tablet} and (orientation: portrait) {
    p {
      margin: 1em auto 0em;
      width: 311px;
    }
  }
  @media ${device.tablet} and (orientation: landscape) {
    h2 {
      font-size: 60px;
    }
  }
`

const Panels = styled.div`
  margin: 52px 0em;
  @media ${device.tablet} and (orientation: portrait) {
    margin: 52px 20px;
  }
  @media ${device.laptop} and (orientation: landscape) {
    margin: 11px 00px;
    display: flex;
    justify-content: space-between;
  }
`

const Panel = styled.section`
  margin: 60px 0em 0em;
  display: flex;
  flex-direction: column;
  align-items: center;

  p:first-of-type {
    font-family: ${fonts.secondary};
    font-size: 40px;
    text-align: center;
    font-weight: 800;
    margin-bottom: 0em;
    padding: 0em;
    letter-spacing: -1.25px;
    line-height: 48px;
    color: ${colors.primary2};
  }
  p:last-of-type {
    font-size: 22px;
    text-align: center;
  }
  @media ${device.tablet} and (orientation: portrait) {
    margin: 60px 0em 0em;
    display: flex;
    flex-direction: ${({ reverse }) => (reverse ? "row" : "row-reverse")};
    align-items: center;
    justify-content: space-between;
    p:first-of-type {
      text-align: left;
      padding-right: 2em;
    }
    p:last-of-type {
      width: 311px;
      text-align: left;
    }
  }
  @media ${device.tablet} and (orientation: landscape) {
    .special-paragraph {
      width: 295px;

      margin-left: 0em;
    }
    .long-paragraph {
      min-width: 325px;
    }
    .middle-paragraph {
      min-width: 315px;
    }
    p:last-of-type {
      width: 311px;
      margin-bottom: 0;
      padding-bottom: 0px;
    }
  }
`

export const WhatWeDo = () => {
  return (
    <StyledWhatWeDo>
      <div className="column-container">
        <Title>
          <h2>What we do</h2>
          <p>
            Guided by our experience, we live out our purpose by pursuing three
            collective goals:
          </p>
        </Title>
        <Panels>
          <Panel>
            <img src={poverty} alt="animated man holding hand up to lady" />
            <div>
              <p>Lessen poverty.</p>
              <p className="long-paragraph">
                We bridge the disadvantage gap with the education, tools, and
                resources needed to rise from poverty.
              </p>
            </div>
          </Panel>
          <Panel reverse>
            <img
              src={leaders}
              alt="animated lady putting a cap on another lady"
            />
            <div>
              <p>Build leaders.</p>
              <p className="middle-paragraph">
                We develop and equip NextGen leaders by modeling authentic
                emotional intimacy and self-work.
              </p>
            </div>
          </Panel>
          <Panel>
            <img
              style={{ width: "210px" }}
              src={peace}
              alt="animated people holding up olive branch"
            />
            <div>
              <p>Promote peace.</p>
              <p className="special-paragraph">
                We gather historically excluded groups in an inclusive space of
                spirituality.
              </p>
            </div>
          </Panel>
        </Panels>
      </div>
    </StyledWhatWeDo>
  )
}
