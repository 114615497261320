import styled from "@emotion/styled"
import React, { useEffect, useState, useRef, useContext } from "react"
import { fonts, colors, device } from "./layout/GlobalStyles"
import arrowRight from "../images/arrow-red-right-new.svg"
import { Link } from "gatsby"
import { SiteContextProvider } from "../layout/SiteContextProvider"
import { siteContext } from "../layout/SiteContextProvider"

const Container = styled.span`
  position: relative;
  cursor: pointer;
  color: ${colors.primary2};
  font-weight: 700;
`

const Text = styled.span`
  box-shadow: 0 8px 50px 0 rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 35px 25px;
  bottom: 55px;
  transition: opacity 0.4s cubic-bezier(0.33, 1, 0.68, 1);
  z-index: ${({ toolTipMobile }) => (toolTipMobile ? "1" : "-200")};
  opacity: ${({ toolTipMobile }) => (toolTipMobile ? "1" : "0")};
  transition: opacity 0.4s cubic-bezier(0.33, 1, 0.68, 1);
  background-color: ${({ purpose }) =>
    purpose ? `${colors.white}` : `${colors.secondary203}`};
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  .general-text {
    text-decoration: none;
    color: ${colors.dark3};
    font-family: ${fonts.primary};
    font-size: 22px;
    line-height: 32px;
    letter-spacing: -0.5625px;
  }

  .link-text {
    padding: 0;
    margin-top: -10px;
    margin-bottom: -15px;
    display: flex;
    align-items: center;
    img {
      height: 100%;
      width: 18px;
      transition: 0.4s cubic-bezier(0.33, 1, 0.68, 1);
      left: ${({ buttonHover }) => (buttonHover ? "2px" : "-3px")};
      position: relative;
    }
    p {
      position: relative;
      font-size: 22px;
      left: 55px;
      bottom: 2px;
      color: ${colors.dark3};
      font-weight: 900;
      padding: 0;
    }
  }
  .triangle-container {
    display: flex;
    display: none;
    flex-direction: column;
    align-items: center;

    .triangle {
      width: 50px;
      height: 50px;
      position: relative;
      top: 50px;
      transform: rotate(90deg);
    }
  }
  p {
    min-width: 279px;
    color: ${colors.secondary403};
  }
  @media ${device.tablet} {
    position: absolute;
    opacity: ${({ hovered }) => (hovered ? "1" : "0")};
    z-index: 200;
    transition: opacity 0.4s cubic-bezier(0.33, 1, 0.68, 1);
  }
  @media ${device.laptop} {
    left: -165px;
    padding: 55px 55px 0px;
    bottom: 50px;
    transition: opacity 0.4s cubic-bezier(0.33, 1, 0.68, 1);
    z-index: ${({ hovered }) => (hovered ? "1" : "-200")};
    opacity: ${({ hovered }) => (hovered ? "1" : "0")};
    transition: opacity 0.4s cubic-bezier(0.33, 1, 0.68, 1);

    display: flex;
    flex-direction: column;
    align-items: center;
    .link-text {
      margin-top: -10px;
      margin-bottom: -25px;
      p {
        left: 60px;
      }
    }

    .triangle-container {
      display: flex;

      display: block;
      flex-direction: column;
      align-items: center;
      .triangle {
        width: 70px;
        height: 70px;
        position: relative;
        top: 20px;
        background: ${({ purpose }) =>
          purpose ? `${colors.white}` : `${colors.secondary203}`};
        transform: rotate(135deg);
      }
    }

    p {
      min-width: 279px;
      color: ${colors.secondary403};
    }
  }
`

export const Tooltip = ({
  children,
  text,
  hovered,
  toolTipMobile,
  buttonText,
  setToolTipMobile,
  setHovered,
  purpose,
}) => {
  const menuRef = useRef()
  const [buttonHover, setButtonHover] = useState(false)
  const { forceOpen, setForceOpen } = useContext(siteContext)
  // let purpose = false
  useEffect(e => {
    let handler = e => {
      if (!menuRef.current.contains(e.target)) {
        setToolTipMobile(false)
      }
    }
    document.addEventListener("mousedown", handler)
    return () => document.removeEventListener("mousedown", handler)
  })
  return (
    <Container>
      <span
        onMouseLeave={() => setHovered(false)}
        onMouseOver={() => setHovered(true)}
      >
        <span style={{ fontWeight: "700" }}>{children}</span>
        <Text
          purpose={purpose}
          ref={menuRef}
          buttonHover={buttonHover}
          toolTipMobile={toolTipMobile}
          hovered={hovered}
        >
          <p style={{ fontWeight: "400" }} className="general-text">
            {text}
          </p>
          <Link
            onClick={() => setForceOpen(true)}
            onMouseLeave={() => setButtonHover(false)}
            onMouseOver={() => setButtonHover(true)}
            to="/purpose/#glossary"
          >
            <div className="link-text">
              <p>{buttonText}</p>
              <img src={arrowRight} alt="go to glossary" />
            </div>
          </Link>

          <div className="triangle-container">
            <div className="triangle"></div>
          </div>
        </Text>
      </span>
    </Container>
  )
}
