import styled from "@emotion/styled"
import React, { useEffect, useState, useContext } from "react"
import redArrow from "../images/arrow-red.svg"
import { fonts, colors, device } from "./layout/GlobalStyles"
import { SiteContextProvider } from "../layout/SiteContextProvider"
import { siteContext } from "../layout/SiteContextProvider"

const glossaryItems = [
  { name: "BIPOC", description: "Back, Indigenous, and People of Color" },
  {
    name: "Faith-based/religious organization",
    description: `We center the spirituality of BIPOC communities while welcoming people of all ages, faiths, and backgrounds to see, reveal, and honor God’s immeasurable splendor within and among them. We celebrate and affirm all people irrespective of their past, present, or future moral or spiritual story. For those participating in our religious rituals, we require them to believe in God as they understand them. We don't proselytize our faith in our Foundation work.`,
  },
  {
    name: "Heteronormative",
    description:
      "The assumption that only the sexual or romantic behaviors between people of the opposite sex are natural and immutable.",
  },
  {
    name: "Historically excluded / historically monetized",
    description: "Historically excluded / historically monetized",
  },
  {
    name: "Equity",
    description: (
      <p>
        We use Policy Link’s definition of Equity: just and fair inclusion in a
        society where all can participate, prosper and reach their full
        potential. Unlocking the promise of the nation by unleashing the promise
        in us all.{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.policylink.org/about-us/equity-manifesto"
        >
          Read the Equity Manifesto.
        </a>
      </p>
    ),
  },
  {
    name: "LEAP",
    description: "Learning Enrichment and Acceleration Program.",
  },
  {
    name: "LGBTQ+",
    description: (
      <p>
        The acronym stands for Lesbian, Gay, Bisexual, Transgender, Queer,
        Questioning, Asexual, and Allies. The + denotes that it is not a
        comprehensive list representing all the various aspects of sexuality;
        visit{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.hrc.org/resources/glossary-of-terms"
        >
          HRC’s Gender/Sexual Orientation Glossary
        </a>{" "}
        for additional categories. Although MLIFE is not opposed to heterosexual
        marriage, we are against heteronormative culture. See heteronormative.
      </p>
    ),
  },
  {
    name: "Live in the margins / Living in the Margins",
    description: `We define these as Individuals or families living on less than US $1.90 per person per day in 2011 purchasing power parity (PPP) in the world's poorest countries. In lower-and upper-middle-income countries, $3.20 and $5.50 per person per day, respectively.`,
  },
  {
    name: "MLOVE",
    description: "Mwangi Mukami Leaders of Openness, Virtue, and Empathy.",
  },
  { name: "NIE", description: "Newspapers in Education." },
  {
    name: "Poverty",
    description: "See Live in the Margins /Living in the Margins.",
  },
  { name: "RAPID", description: "Relief Aid for People in Despair." },
  {
    name: "SDGs",
    description: (
      <p>
        The United Nations adopted the Sustainable Development Goals (SDGs) in
        2015 to provide a shared blueprint for peace and prosperity for people
        and the planet, now and into the future.{" "}
        <a target="_blank" rel="noreferrer" href="https://sdgs.un.org/goals">
          Read about the goals.
        </a>
      </p>
    ),
  },
  {
    name: "Spirituali-TEA",
    description:
      "MLIFE blended spiritually rooted, afro-centric, holistic programs at the intersection of technology, education, and arts.",
  },
  {
    name: "Tea",
    description:
      "Translated as “I am because we are,” Defined by the African Journal of Social Work (AJSW) as “a collection of values and practices that people of Africa or African origin view as making people authentic human beings. While the nuances of these values and practices vary across different ethnic groups, they all point to one thing – an authentic individual human being is part of a larger and more significant relational, communal, societal, environmental, and spiritual world.”",
  },
  {
    name: "Ubuntu",
    description:
      "We use Amy Jo Hutchison’s definition, “[People] not poor enough to receive help and don’t make enough to get by.” We also use a Multidimensional Poverty Index (MPI) to measure the complexity of poverty in people’s lives in lower-and upper-middle-income countries.",
  },
  {
    name: "Extreme poverty",
    description:
      "The IPL defines extreme poverty as the consumption (or income) of less than US$1.90 a day in 2011 purchasing power parity (PPP).",
  },
]

const StyledGlossary = styled.div`
  padding: 72px 0px;
  display: flex;
  background: ${colors.primary1};
  flex-direction: column;
  height: ${({ mainOpen }) => (mainOpen ? "100%" : "800px")};
  .invisible {
    transition: 0.2s ease-in;
    opacity: 0;
  }
  .visible {
    transition: 0.2s ease-in;
    opacity: 1;
  }
  span {
    font-weight: 900;
    color: ${colors.primary2};
    text-decoration: underline;
  }
  a {
    color: ${colors.primary2};
    font-weight: 500;
    &:hover {
      text-decoration: underline;
    }
  }
  h3 {
    font-size: 60px;
    margin: 0 0 23px 0px;
    font-weight: 800;
    letter-spacing: -1.88px;
    line-height: 65px;
  }
  p {
    letter-spacing: -0.69px;
    line-height: 36px;
    font-size: 18px;
    margin: 16px 0 16px 0px;
    color: ${colors.dark3};
  }

  .arrow-container {
    margin-top: 35px;
    display: flex;
    align-items: center;
    button {
      display: flex;
      align-items: center;
      padding: 0;
      border: none;
      background: ${colors.primary1};
    }
    .red-line {
      background: #861b1f;
      height: 2px;
      width: 170px;
      margin-right: 15px;
    }
    .big-arrow {
      position: absolute;
      right: 25px;
      -webkit-transform: ${({ mainOpen }) =>
        mainOpen ? "rotate(0deg)" : "rotate(180deg)"};
      transform: ${({ mainOpen }) =>
        mainOpen ? "rotate(0deg)" : "rotate(180deg)"};
      transition: transform 0.2s cubic-bezier(0.33, 1, 0.68, 1);
    }
    p {
      color: #861b1f;
      font-size: 16px;
    }
  }

  @media ${device.tablet} {
    padding: 72px 0px 0px;
    height: ${({ mainOpen }) => (mainOpen ? "100%" : "640px")};
    .arrow-container {
      p {
        font-size: 30px;
        font-weight: 900;
      }
    }
  }
  @media ${device.laptop} {
    height: ${({ mainOpen }) => (mainOpen ? "100%" : "800px")};
    margin-bottom: ${({ mainOpen }) => (mainOpen ? "00px" : "-280px")};
    p {
      font-size: 22px;
    }
    padding: 128px 0px;
    .arrow-container {
      margin: 0 auto;
      justify-content: flex-end;
      width: 1091px;
      margin-top: 28px;
      p {
        letter-spacing: -0.94px;
        line-height: 36px;
        text-align: center;
        margin-right: 18px;
      }
      .red-line {
        background: #861b1f;
        height: 2px;
        left: 0px;
        position: absolute;
        width: 60vw;
        margin-right: 15px;
      }
      .big-arrow {
        img {
          width: 32px;
        }
        width: 32px;
        position: static;
      }
    }
    .paragraph-container {
      justify-content: space-between;
      display: flex;
      width: 1091px;
      p:first-of-type {
        width: 518px;
      }
      p:last-of-type {
        width: 480px;
      }
    }
  }
`

const DropDownContainer = styled.div`
  position: relative;
  padding: 10px 25px;
  height: ${({ mainOpen }) => (mainOpen ? "100%" : "0px")};
  opacity: ${({ mainOpen }) => (mainOpen ? "1" : "0")};
  transition: 0.2s cubic-bezier(0.33, 1, 0.68, 1);
  p {
    width: 220px;
    font-size: 30px;
    font-weight: 900;
    letter-spacing: -0.94px;
    line-height: 36px;
  }
`

const List = styled.ul`
  padding-left: 0;
  list-style: none;
  @media ${device.laptop} {
    position: relative;
    left: 20px;
  }
`

const Item = styled.li`
  button {
    cursor: pointer;
    padding: 0;
    display: flex;
    background: ${colors.primary1};
    flex-direction: row;
    align-items: center;
    border: none;
  }

  .name-container {
    display: flex;

    align-items: center;
    .small-arrow {
      -webkit-transform: ${({ subOpen, index, stateIndex }) =>
        subOpen && index === stateIndex ? "rotate(0deg)" : "rotate(180deg)"};
      transform: ${({ subOpen, index, stateIndex }) =>
        subOpen && index === stateIndex ? "rotate(0deg)" : "rotate(180deg)"};
      transition: transform 0.2s cubic-bezier(0.33, 1, 0.68, 1);
      width: 26px;
      margin-right: 26px;
    }
    button {
      color: ${colors.dark3};
      z-index: 2;
      font-family: ${fonts.primary};
      font-size: 30px;
      margin: 15px 0px;
      font-weight: 900;
      letter-spacing: -0.94px;
      line-height: 36px;
      text-align: left;
    }
    p {
    }
  }

  .description-container {
    p {
      margin-left: 52px;
      height: ${({ subOpen, index, stateIndex }) =>
        subOpen && stateIndex === index ? "100%" : "0px"};
      opacity: ${({ subOpen, index, stateIndex }) =>
        subOpen && stateIndex === index ? "1" : "0"};
      transition: 0.2s cubic-bezier(0.33, 1, 0.68, 1);
      font-size: 22px;
      font-weight: 400;
      text-align: left;
      letter-spacing: -0.69px;
      width: 280px;
      line-height: 36px;
      p {
        padding: 0;
        margin: 0;
      }
    }
  }
  @media ${device.laptop} {
    .description-container {
      p {
        min-width: 740px;
      }
    }
    .name-container {
      button {
        width: auto;
      }
    }
  }
`

const BottomContainer = styled.div`
  width: 1096px;
  z-index: 3;
  h5 {
    color: ${colors.dark3};
    font-size: 30px;
    font-weight: 900;
    letter-spacing: -0.94px;
    line-height: 36px;
    font-family: ${fonts.primary};
    margin-right: 18px;
  }
`

export const Glossary = () => {
  const [mainOpen, setMainOpen] = useState(false)
  const [subOpen, setSubOpen] = useState(false)
  const [stateIndex, setStateIndex] = useState(null)
  const { forceOpen } = useContext(siteContext)
  useEffect(() => {
    forceOpen && setMainOpen(true)
  }, [])
  const itemClick = index => {
    setStateIndex(index)
    subOpen && index === stateIndex ? setSubOpen(false) : setSubOpen(true)

    // setSubOpen(subOpen => !subOpen)
  }
  return (
    <StyledGlossary mainOpen={mainOpen} subOpen={subOpen}>
      <div className="container">
        <h3>Glossary</h3>
        <div className="paragraph-container">
          <p>
            We know it is complex to navigate a world where words change often.
            At MLIFE, we are committed to taking this learning journey with you.
            We recognize that some words stir tension. We join you in this
            tension with the hope and goal of building a better world where
            everyone is loved, honored, and respected.
          </p>
          <p>
            Except for a few terms and phrases, we have borrowed{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.hrc.org/resources/glossary-of-terms"
            >
              <span>Our LGBTQ+ Glossary</span>
            </a>{" "}
            from the Human Rights Campaign and{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.racialequitytools.org/glossary"
            >
              {" "}
              <span>Our Racial Equity Terms</span>
            </a>{" "}
            from MP Associates, Center for Assessment and Policy Development,
            and World Trust Educational Services, October 2021.
          </p>
        </div>
      </div>
      <div className="arrow-container">
        <div className="red-line"></div>
        <button onClick={() => setMainOpen(mainOpen => !mainOpen)}>
          <p>{mainOpen ? "Close Terms" : "Open Terms"}</p>
          <img className="big-arrow" src={redArrow} alt="open-menu" />
        </button>
      </div>
      <DropDownContainer mainOpen={mainOpen} subOpen={subOpen}>
        <List className="container">
          {glossaryItems.map((item, index) => {
            return (
              <Item
                mainOpen={mainOpen}
                subOpen={subOpen}
                index={index}
                stateIndex={stateIndex}
              >
                <div className="name-container">
                  <button onClick={() => itemClick(index)}>
                    <img
                      className="small-arrow"
                      src={redArrow}
                      alt="open-menu"
                    />
                  </button>
                  <button onClick={() => itemClick(index)}>{item.name}</button>
                </div>
                <div className="description-container">
                  <p>{item.description}</p>
                </div>
              </Item>
            )
          })}
        </List>
      </DropDownContainer>
      <BottomContainer
        className={`container ${mainOpen ? "visible" : "invisible"}`}
      >
        <h5>Other Terms</h5>
        <p>
          We recognize the work of others in the quest for justice, equity,
          diversity, and inclusion. If you’re interested in learning more,
          please view the{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://drive.google.com/file/d/1-h0EWxqHAZUfheFvuFRtVpEz5ODSxuI6/view"
          >
            Racial Equity Tools Glossary{" "}
          </a>{" "}
          and{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.hrc.org/resources/glossary-of-terms"
          >
            Gender/Orientation Glossary{" "}
          </a>{" "}
          for additional terms and their meaning.{" "}
        </p>
      </BottomContainer>
    </StyledGlossary>
  )
}
