import styled from "@emotion/styled"
import React, { useState } from "react"
import { colors, fonts, device } from "./layout/GlobalStyles"
import { StaticImage } from "gatsby-plugin-image"
import { PanelContainer } from "./panels/Panel-Container"
import { Button } from "./layout/StyledComponents"
import { Fancybox } from "./Fancy-Box"
import { Tooltip } from "./Tooltip"

const StyledOurStoryBegins = styled.div``

const MainPicturePanel = styled.section`
  position: relative;
  background: #fffaf1;
  z-index: 0;

  .main-image {
    height: 85vh;
    position: absolute;
    top: 0em;
    width: 180%;
    right: 0em;
    z-index: -1;
  }
  button,
  button:active,
  button:focus {
    outline: none;
  }
  .text {
    z-index: 1;
    h4,
    p {
      color: ${colors.white};
    }
    h4 {
      font-family: ${fonts.secondary};
      font-size: 60px;
      font-weight: 800;
      margin: 0em;
      padding-top: 150px;
      letter-spacing: -1.88px;
      line-height: 65px;
    }
    p {
      padding-right: 5em;
      font-size: 22px;
      letter-spacing: -0.69px;
      line-height: 36px;
    }
  }
  @media ${device.tablet} and (orientation: portrait) {
    .main-image {
      height: 85vh;
      position: absolute;
      top: -2em;
      width: 100%;
      right: 0em;
      z-index: -1;
    }
    .text {
      z-index: 2;
      h4,
      p {
        z-index: 2;
        color: ${colors.white};
      }
      h4 {
        z-index: 2;
        font-family: ${fonts.secondary};
        font-size: 60px;
        font-weight: 800;
        margin: 0em;
        width: 420px;
        padding-top: 328px;
        letter-spacing: -1.88px;
        line-height: 65px;
      }
      p {
        padding-right: 0em;
      }
    }
  }
  @media ${device.laptop} {
    min-height: 1795px;
    padding-bottom: 120px;
    .main-image {
      height: 613px;
      position: absolute;
      top: -2em;
      width: 100%;
      right: 0em;
      z-index: -1;
    }
    .text {
      position: relative;
      bottom: 40px;
      padding-bottom: 120px;
      h4 {
        padding: 238px 0px 0px;
        width: 450px;
      }
    }
  }
  @media only screen and (min-width: 1600px) {
    .main-image {
      height: 720px;
    }
  }
  @media only screen and (min-width: 2000px) {
    .main-image {
      height: 770px;
    }
  }
  @media only screen and (min-width: 2400px) {
    .main-image {
      height: 820px;
    }
  }
`

const Panel = styled.div`
  margin-top: 40px;
  ul {
    padding-left: 1.5em;
  }
  p,
  li {
    font-family: ${fonts.primary};
    font-size: 22px;
    font-weight: 400;
    letter-spacing: -0.69px;
    line-height: 36px;
  }

  @media ${device.laptop} {
    .two-paragraphs {
      position: relative;
      top: 25px;
    }
    .par,
    ul {
      margin-top: 30px;
      width: 535px;
    }
    .special-image {
      height: 344px;
    }
    img {
      width: 445px;
    }
  }
`

const Panels = styled.div`
  z-index: -2;

  padding: 260px 0px 84px;
  @media ${device.laptop} {
    padding: 110px 0px 188px;
    margin-bottom: -180px;
  }
  @media only screen and (min-width: 2100px) {
    margin-top: 100px;
    margin-bottom: -180px;
  }
`

const tooltipText = [
  "We define these as Individuals or families living on less than US $1.90 per person per day in 2011 purchasing power parity (PPP) in the world's poorest countries. In lower-and upper-middle-income countries, $3.20 and $5.50 per person per day, respectively.",
]

export const OurStoryBegins = ({
  setToolTipMobile,
  toolTipMobile,
  hovered,
  setHovered,
}) => {
  const hoverAction = (bool, name) => {
    setName(name)
    setHovered(bool)
  }
  const [name, setName] = useState("")
  return (
    <StyledOurStoryBegins>
      <MainPicturePanel>
        <StaticImage
          className="main-image"
          src="../images/founder-frontal.png"
          placeholder="none"
          quality={100}
          alt="MLife founder talking to a student"
        />
        <div className="container text">
          <h4>Our story begins with...</h4>
          <p className="par">with Our Founder Mwangi Mukami </p>
          {/* <a
            data-fancybox
            target="_blank"
            rel="noopener noreferrer"
            href="https://vimeo.com/717156134"
          > */}
          <Fancybox options={{ infinite: false }}>
            <Button
              data-fancybox="gallery"
              data-src="https://vimeo.com/717156134"
              className="video-button"
              color="yellow"
            >
              Watch his story
            </Button>
          </Fancybox>
          {/* </a> */}
        </div>
        <Panels>
          <Panel>
            <PanelContainer>
              {" "}
              <StaticImage
                src="../images/mwangi-child.png"
                placeholder="none"
                quality={100}
                alt="Mwangi as a child"
              />
              <p className="par">
                The youngest of 9 children born to a single mother, Mwangi grew
                up in{" "}
                <span
                  onClick={() => setToolTipMobile(active => !active)}
                  onMouseOver={() => hoverAction(true, "extreme poverty")}
                  onMouseLeave={() => hoverAction(false, "")}
                >
                  {name === "extreme poverty" ? (
                    <Tooltip
                      buttonText="View full glossary"
                      toolTipMobile={toolTipMobile}
                      setToolTipMobile={setToolTipMobile}
                      text={tooltipText[0]}
                      hovered={hovered}
                      setHovered={setHovered}
                    >
                      extreme poverty
                    </Tooltip>
                  ) : (
                    <span className="special">extreme poverty</span>
                  )}
                </span>{" "}
                Despite struggling in school and at home, Mwangi got what most
                kids in Nairobi never will - a chance.{" "}
              </p>
            </PanelContainer>
          </Panel>
          <Panel>
            <PanelContainer className="second" reverse>
              <StaticImage
                src="../images/mwangi-obama.png"
                placeholder="none"
                quality={100}
                alt="Mwangi meeting obama"
              />
              <div>
                <ul>
                  <li>Mwangi became a pastor by age 14</li>
                  <li>Leader of a national political movement by age 18</li>
                  <li>Founded an international non-profit by age 20. </li>
                  <li>
                    A household name and rising political star, he struggled
                    with keeping his identity as a gay <br />
                    man a secret.{" "}
                  </li>
                </ul>
              </div>
            </PanelContainer>
          </Panel>
          <Panel>
            <PanelContainer className="second">
              <StaticImage
                className="special-image"
                src="../images/mwangi-speaking.png"
                placeholder="none"
                quality={100}
                alt="Mwangi public speaking"
              />
              <div className="two-paragraphs">
                <p className="par">
                  Blackmailed and ostracized because of his sexuality, Mwangi
                  fled Kenya by age 25 in the middle of the night. He claimed
                  asylum in America and started over. Mwangi built a new life in
                  California - obtaining a bachelor's degree from San Francisco
                  State and his Master of Public Affairs from University of
                  California Berkeley - and founded MLIFE in 2017 to uplift
                  those in need both in California and Kenya.
                </p>
                <p className="par">
                  Mwangi and his family split their time between San Francisco
                  and Nairobi.
                </p>
              </div>
            </PanelContainer>
          </Panel>
        </Panels>
      </MainPicturePanel>
    </StyledOurStoryBegins>
  )
}
