import styled from "@emotion/styled"
import React, { useState } from "react"
import { colors, fonts, device } from "./layout/GlobalStyles"
import { StaticImage } from "gatsby-plugin-image"
import { PanelContainer } from "./panels/Panel-Container"
import { Tooltip } from "./Tooltip"

const StyledPurposeMission = styled.section`
  background: ${colors.secondary203};
  padding: 52px 0px;
  margin: 0em;
  @media ${device.tablet} {
    padding: 52px 0px;
    img {
      height: 470.28px;
      /* width: 580px; */
    }
  }
  @media ${device.tablet} and (orientation: portrait) {
    padding: 52px 0px;
  }
  @media ${device.tablet} and (orientation: landscape) {
    padding: 90px 0px;
  }
`

const Text = styled.div`
  h3 {
    margin: 0em;
    font-weight: 800;
    color: ${colors.primary2};
    font-family: ${fonts.secondary};
    font-size: 60px;
  }
  .sub {
    font-weight: 900;
    color: ${colors.dark3};
    margin: 00px 0px 20px;
    letter-spacing: -0.94px;
    line-height: 36px;
    font-size: 30px;
  }
  .par {
    font-size: 22px;
    margin: 0em 0em 50px;
  }
  @media ${device.tablet} and (orientation: portrait) {
    width: 580px;
  }
  @media ${device.tablet} and (orientation: landscape) {
    margin-right: 46.3px;
    width: 420px;
    margin-top: 20px;
    .sub {
      width: 350px;
      font-weight: 900;
      margin: 0px 0px 20px;
    }
  }
`

const tooltipText = [
  "MLIFE blended spiritually rooted, afro-centric, holistic programs at the intersection of technology, education, and arts.",
  "The acronym stands for Lesbian, Gay, Bisexual, Transgender, Queer, Questioning, Asexual, and Allies.",
]

export const PurposeMission = ({
  setToolTipMobile,
  toolTipMobile,
  hovered,
  setHovered,
  purpose,
}) => {
  const [name, setName] = useState("")
  const hoverAction = (bool, name) => {
    setName(name)
    setHovered(bool)
  }
  return (
    <StyledPurposeMission>
      <PanelContainer reverse>
        <Text>
          <h3>Mission</h3>
          <p className="sub">Our Expansive and Robust Mission.</p>
          <p className="par">
            To build an inclusive, faith-based community for the historically
            excluded and cultivate potential change-makers living in the margins
            using our unique blend of{" "}
            <span
              onClick={() => setToolTipMobile(active => !active)}
              onMouseOver={() => hoverAction(true, "spirituali-TEA")}
              onMouseLeave={() => hoverAction(false, "")}
            >
              {name === "spirituali-TEA" ? (
                <Tooltip
                  purpose={purpose}
                  buttonText="View full glossary"
                  toolTipMobile={toolTipMobile}
                  setToolTipMobile={setToolTipMobile}
                  text={tooltipText[0]}
                  hovered={hovered}
                  setHovered={setHovered}
                >
                  spirituali-TEA
                </Tooltip>
              ) : (
                <div className="special">spirituali-TEA </div>
              )}
            </span>{" "}
            (technology, education, and arts).
          </p>
        </Text>
        <StaticImage
          src="../images/panels/mission-collage.png"
          alt="collage of people and hikers"
          placeholder="none"
          quality={100}
        />
      </PanelContainer>
    </StyledPurposeMission>
  )
}
