import React, { useState, useContext } from "react"
import { PurposeHero } from "../components/Purpose-Hero"
import { GuidingPhilosophy } from "../components/Guiding-Philosophy"
import { WhatWeDo } from "../components/What-We-Do"
import { PurposeMission } from "../components/Purpose-Mission"
import { Vision } from "../components/Vision"
import { WeRiseTogether } from "../components/We-Rise-Together"
import { Glossary } from "../components/Glossary"
import { BecomeOneOfUs } from "../components/Become-One-Of-Us"
import { OurStoryBegins } from "../components/Our-Story-Begins"
import { Seo } from "../components/Seo"
import { siteContext } from "../layout/SiteContextProvider"

const Purpose = () => {
  const [hovered, setHovered] = useState(false)
  const { toolTipMobile, setToolTipMobile } = useContext(siteContext)
  return (
    <>
      <Seo
        title="Our Purpose & Guiding Philosophy"
        description="MLIFE favors ground-truthed expertise and grassroots impact over paternalistic philanthropic models rooted in global white supremacy."
      />
      {/* <Nav /> */}
      <PurposeHero />
      <GuidingPhilosophy />
      <WhatWeDo />
      <PurposeMission
        toolTipMobile={toolTipMobile}
        setToolTipMobile={setToolTipMobile}
        hovered={hovered}
        purpose
        setHovered={setHovered}
      />
      <Vision />
      <WeRiseTogether
        toolTipMobile={toolTipMobile}
        setToolTipMobile={setToolTipMobile}
        hovered={hovered}
        setHovered={setHovered}
      />
      <div id="glossary">
        <Glossary />
      </div>
      <OurStoryBegins
        toolTipMobile={toolTipMobile}
        setToolTipMobile={setToolTipMobile}
        hovered={hovered}
        setHovered={setHovered}
      />
      <BecomeOneOfUs />

      {/* <Footer /> */}
    </>
  )
}

export default Purpose
